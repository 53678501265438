import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  isAuth: JSON.parse(localStorage.getItem("loginData"))?.isAuth
    ? JSON.parse(localStorage.getItem("loginData")).isAuth
    : false,
  name: JSON.parse(localStorage.getItem("loginData"))?.name
    ? JSON.parse(localStorage.getItem("loginData")).name
    : "",
  email: JSON.parse(localStorage.getItem("loginData"))?.email
    ? JSON.parse(localStorage.getItem("loginData")).email
    : "",
  loginData: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData"))
    : "",
    role:"",
  adminLoading: true,
  isAuthLoading: true,
  fullscreen: false,
  activeMenu:1,
  showMenu:''
};

export const adminLoginBypassword = createAsyncThunk(
  "admin/adminLogin",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not registered",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/login`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data,'resp.data');
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signin(state, action) {
      state.isAuth = action.payload.isAuth;
      state.loginData = action.payload.loginData;
      localStorage.setItem("loginData", JSON.stringify(state.loginData));
    },
    signout(state, action) {
      localStorage.removeItem("loginData");
      state.loginData='';
      state.isAuth = false;
    },
    setFullScreen(state, action) {
      state.fullscreen = action.payload;
    },
    setActiveMenu(state,action){
      state.activeMenu=action.payload
    },
    setShowMenu(state,action){
      state.showMenu=action.payload
    }
  }
});
export const authActions = authSlice.actions;
export default authSlice.reducer;
