import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import Switch from '@mui/material/Switch';
import { Baseurl } from "../../config/BaseUrl";
import { useNavigate, useParams } from "react-router-dom";
import { getProductDetails, productUpdate } from "../../redux/Product/ProductSlice";
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const EditProduct = () => {
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const params = useParams();
    // const { loginData } = useSelector((store) => store.auth);select
    const { categoryList } = useSelector((store) => store.category)
    const { subCategoryList } = useSelector((store) => store.subCategory)
    // Here from this states
    const [checked, setChecked] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaKeyword, setMetaKeyword] = useState("");
    const [metaDesc, setMetaDesc] = useState("");
    const [category, setCategory] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [subCategoryId, setSubCategoryId] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [filterSubCategory, setfilterSubCategory] = useState([])
    const [url, setUrl] = useState("");
    const [productInfo, setproductInfo] = useState("");
    const [about, setAbout] = useState("")
    const [ingradient, setIngradient] = useState("")
    const [rating, setRating] = useState("");
    const [type, setType] = useState("")
    const [options, setOptions] = useState("")
    const [gst, setGst] = useState("")
    const [packSize, setPackSize] = useState("")

    const [multi, setMulti] = useState(false)
    const [recommends, setRecommends] = useState(false)
    const [single, SetSingle] = useState(false)
    const [hotProducts, setHotProducts] = useState(false)
    const [trending, setTrending] = useState(false)
    const [offer, setOffer] = useState(false)

    useEffect(() => {
        const filterSubCategorydata = subCategoryList.filter((indSubCat) =>
            indSubCat.categoryId === categoryId
        )
        if (filterSubCategorydata) {
            setfilterSubCategory(filterSubCategorydata)
        }
    }, [categoryId])
    const [packSizes, setPackSizes] = useState([{
        packSize: '',
        imgUrl: '',
        costPrc: "",
        gstCost: '',
        sellingPrice: '',
        gstSell: '',
        mrp: '',
        outofstock: false,
        prime: false,
        stockAuto: false,
    }]);
    const [imageInputs, setImageInputs] = useState([{ id: Date.now(), url: null }]);
    const [textInputs, setTextInputs] = useState([{ id: Date.now(), value: '' }]);
    useEffect(() => {
        const getProduct = async () => {
            if (params.productId) {
                const getProduct = await dispatch(getProductDetails(params.productId))
                if (getProduct.payload.success) {
                    setName(getProduct.payload.grocery.ItemName)
                    setMetaTitle(getProduct.payload.grocery.Title)
                    setMetaDesc(getProduct.payload.grocery.Description)
                    setCategory(getProduct.payload.grocery.Category)
                    setCategoryId(getProduct.payload.grocery.CatId)
                    setSubCategoryId(getProduct.payload.grocery.SubCatId)
                    setSubCategory(getProduct.payload.grocery.SubCat)
                    setproductInfo(getProduct.payload.grocery.ProductInfo)
                    setAbout(getProduct.payload.grocery.About)
                    setIngradient(getProduct.payload.grocery.Ingredient)
                    setRating(getProduct.payload.grocery.Rating)
                    setType(getProduct.payload.grocery.Type)
                    setOptions(getProduct.payload.grocery.Options)
                    setMulti(getProduct.payload.grocery.Multi)
                    setRecommends(getProduct.payload.grocery.Recommends)
                    SetSingle(getProduct.payload.grocery.Single)
                    setHotProducts(getProduct.payload.grocery.HotProducts)
                    setTrending(getProduct.payload.grocery.Trending)
                    setOffer(getProduct.payload.grocery.Offers)
                    const getKeywords = getProduct.payload.grocery.KeyWords.map((indKeyword) => (
                        {
                            id: Date.now(),
                            value: indKeyword
                        }
                    ));
                    setTextInputs(getKeywords);
                    const images = getProduct.payload.grocery.seriesImages.map((indImage) => ({
                        id: Date.now(),
                        url: indImage.url
                    }));
                    setImageInputs(images);
                    const productPackSizes = getProduct.payload.grocery.PackSizes.map((indPack) => ({
                        packSize: indPack.PackSize,
                        imgUrl: indPack.ImgUrlDesk,
                        costPrc: indPack.CostPrc,
                        gstCost: indPack.GstCost,
                        sellingPrice: indPack.SellingPrice,
                        gstSell: indPack.GstSellPrc,
                        mrp: indPack.Mrp,
                        outofstock: indPack.OutOfStock,
                        prime: indPack.Prime,
                        stockAuto: indPack.StockAutoUpdate
                    }));
                    setPackSizes(productPackSizes);
                }
            }
        }
        getProduct()
    }, [params.productId])


    const handlePackChange = (index, field, value) => {
        let updatedPackSizes = [...packSizes];
        if (field === 'prime' && value === true) {
            updatedPackSizes = updatedPackSizes.map((pack, i) => ({
                ...pack,
                prime: i === index ? true : false, // Only the selected pack will have prime as true
            }));
        } else {
            updatedPackSizes[index][field] = value;
        }
        setPackSizes(updatedPackSizes);
    };
    const handleImage = async (event, index) => {
        const file = event.target.files[0];
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'yrevfjck'); // Replace with your Cloudinary upload preset
        const response = await axios.post(
            'https://api.cloudinary.com/v1_1/dexterous-technology/image/upload', // Replace with your Cloudinary cloud name
            formData
        );
        handlePackChange(index, 'imgUrl', response.data.secure_url)
    }
    const addNewPackSize = () => {
        setPackSizes([...packSizes, {
            id: Date.now().toString(),
            packSize: '',
            imgUrl: '',
            costPrc: "",
            gstCost: '',
            sellingPrice: '',
            gstSell: '',
            mrp: '',
            outofstock: false,
            prime: false,
            stockAuto: false,
        }]);
    };
    const deletePackSize = (index) => {
        const updatedPackSizes = packSizes.filter((_, i) => i !== index);
        setPackSizes(updatedPackSizes);
    };
    const handleImageChange = async (index, event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'yrevfjck'); // Replace with your Cloudinary upload preset

        try {
            const response = await axios.post(
                'https://api.cloudinary.com/v1_1/dexterous-technology/image/upload', // Replace with your Cloudinary cloud name
                formData
            );

            const updatedInputs = [...imageInputs];
            updatedInputs[index].url = response.data.secure_url;
            setImageInputs(updatedInputs);

        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const addInput = () => {
        setImageInputs([...imageInputs, { id: Date.now(), url: null }]);
    };

    const removeInput = (index) => {
        const updatedInputs = imageInputs.filter((_, i) => i !== index);
        setImageInputs(updatedInputs);
    };
    const handleInputChange = (index, event) => {
        const updatedInputs = [...textInputs];
        updatedInputs[index].value = event.target.value;
        setTextInputs(updatedInputs);
    };
    const addInputChange = () => {
        setTextInputs([...textInputs, { id: Date.now(), value: '' }]);
    };

    const removeInputChange = (index) => {
        const updatedInputs = textInputs.filter((_, i) => i !== index);
        setTextInputs(updatedInputs);
    };
    const handleChangeCategory = (evt) => {
        const value = evt.target.value
        setCategoryId(value)
        const textValue = categoryList.find((indcat) =>
            indcat._id === value
        )
        if (textValue) {
            setCategory(textValue.name)
        }

    }

    const handleSubCategory = (evt) => {
        const value = evt.target.value
        setSubCategoryId(value)
        const textValue = subCategoryList.find((indcat) =>
            indcat._id === value
        )
        if (textValue) {
            setSubCategory(textValue.name)
        }
    }

    const handleProduct = async () => {
        if (categoryId !== '' || subCategoryId !== '') {
            let newPackSizes = [];
            const arrangePackSize = packSizes.map((indPack) => {
                const indexPack = {
                    PackSize: indPack.packSize,
                    ImgUrlMbl: indPack.imgUrl,
                    ImgUrlMblIcon: indPack.imgUrl,
                    ImgUrlDesk: indPack.imgUrl,
                    ImgUrlDeskIcon: indPack.imgUrl,
                    CostPrc: indPack.costPrc,
                    GstCost: indPack.gstCost,
                    SellingPrice: indPack.sellingPrice,
                    GstSellPrc: indPack.gstSell,
                    Mrp: indPack.mrp,
                    OutOfStock: indPack.outofstock,
                    Prime: indPack.prime,
                    StockAutoUpdate: indPack.stockAuto,
                };
                newPackSizes.push(indexPack);
            });
            let randomNumber = '';
            for (let i = 0; i < 4; i++) {
                randomNumber += Math.floor(Math.random() * 10); // Generates a digit between 0 and 9
            }
            randomNumber = parseInt(randomNumber); // Convert the string to a number
            const names = textInputs.map(indInput => indInput.value);
            const formData = {
                PackSizes: newPackSizes,
                ItemName: name,
                Url: `${category}-${subCategory}-${name}`,
                Category: category,
                CatId: categoryId,
                SubCat: subCategory,
                SubCatId: subCategoryId,
                KeyWords: names,
                seriesImages: imageInputs,
                Description: metaDesc,
                Title: metaTitle,
                About: about,
                Ingredient: ingradient,
                ProductInfo: productInfo,
                Type: type,
                Rating: "4.5",
                Brand: "KiranaGhar",
                Options: options,
                Recommends: recommends,
                HotProducts: hotProducts,
                Trending: trending,
                Offers: offer,
                Multi: multi,
                Single: single,
                ProductId: params.productId,
            };
            const response = await dispatch(productUpdate(formData))
            if (response.payload.success) {
                alert(`Product With ProductId:${params.productId} is updated`)
                setName("")
                setMetaTitle("")
                setMetaDesc("")
                setCategory("")
                setCategoryId("")
                setSubCategoryId("")
                setSubCategory("")
                setproductInfo("")
                setAbout("")
                setIngradient("")
                setRating("")
                setType("")
                setOptions("")
                setMulti(false)
                setRecommends(false)
                SetSingle(false)
                setHotProducts(false)
                setTrending(false)
                setOffer(false)
                setTextInputs([{ id: Date.now(), value: '' }])
                setImageInputs([{ id: Date.now(), url: null }])
                setPackSizes([{
                    packSize: '',
                    imgUrl: '',
                    costPrc: "",
                    gstCost: '',
                    sellingPrice: '',
                    gstSell: '',
                    mrp: '',
                    outofstock: false,
                    prime: false,
                    stockAuto: false,
                }])
                navigate("/admin/productList")
            }
        } else {
            alert('Please fill all the fields')
        }
    };


    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-2 space-y-6">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div
                            className="flex items-center justify-between py-2 px-5"
                            style={{
                                backgroundColor: "#4D44B5",
                                color: "#fff",
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                            }}
                        >
                            <h4
                                className="text-lg font-medium text-default-950 capitalize"
                                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
                            >
                                Edit Product Details
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-2 gap-4">
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fName"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Category:
                                        </label>
                                        <div style={{ width: "80%" }}
                                        >
                                            <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                                <InputLabel id="demo-simple-Case-Type" className="inputcenter">Select Category</InputLabel>
                                                <Select
                                                    labelId="demo-simple-Case-Type"
                                                    id="demo-simple-select"
                                                    value={categoryList._id}
                                                    label="Select Category"
                                                    onChange={(e) => handleChangeCategory(e)}
                                                    style={{ height: '35px' }}
                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                >
                                                    {categoryList.map((test, index) =>
                                                        <MenuItem selected={test._id === categoryId ? true : false} value={test._id} key={index}>{test.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fName"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Sub Category:
                                        </label>
                                        <div style={{ width: "80%" }}
                                        >
                                            <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                                <InputLabel id="demo-simple-Case-Type" className="inputcenter">Select Sub Category</InputLabel>
                                                <Select
                                                    labelId="demo-simple-Case-Type"
                                                    id="demo-simple-select"
                                                    // value={caseType}
                                                    label="Select Sub Category"
                                                    onChange={(e) => handleSubCategory(e)}
                                                    style={{ height: '35px' }}
                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                >
                                                    {filterSubCategory.map((test, index) =>
                                                        <MenuItem
                                                            selected={test._id === subCategoryId ? true : false}
                                                            value={test._id} key={index}
                                                        >
                                                            {test.name}
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fName"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Name :
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <TextField
                                                label="Enter Name"
                                                variant="outlined"
                                                className={
                                                    nameError !== ""
                                                        ? "textfield error_class"
                                                        : "textfield"
                                                }
                                                type="text"
                                                id="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            // className={classes.textfield}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            About:
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <TextField
                                                label="Enter About"
                                                variant="outlined"
                                                className="textfield"
                                                type="text"
                                                id="text"
                                                value={about}
                                                onChange={(e) => setAbout(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Ingredient*:
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <TextField
                                                label="Enter Ingradient"
                                                variant="outlined"
                                                className="textfield"
                                                type="text"
                                                id="text"
                                                value={ingradient}
                                                onChange={(e) => setIngradient(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Rating:
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <TextField
                                                label="Enter Rating"
                                                variant="outlined"
                                                className="textfield"
                                                type="text"
                                                id="text"
                                                value={rating}
                                                onChange={(e) => setRating(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Product Info :
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <TextField
                                                label="Enter Product Info"
                                                variant="outlined"
                                                className="textfield"
                                                multiline
                                                rows={2}
                                                type="text"
                                                id="text"
                                                value={productInfo}
                                                onChange={(e) => setproductInfo(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Description :
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <TextField
                                                label="Enter Meta Description"
                                                variant="outlined"
                                                className="textfield"
                                                type="text"
                                                id="text"
                                                multiline
                                                rows={2}
                                                value={metaDesc}
                                                onChange={(e) => setMetaDesc(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Title:
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <TextField
                                                label="Enter Meta Title"
                                                variant="outlined"
                                                className="textfield"
                                                type="text"
                                                id="text"
                                                value={metaTitle}
                                                onChange={(e) => setMetaTitle(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Type:
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <TextField
                                                label="Enter Product Info"
                                                variant="outlined"
                                                className="textfield"
                                                type="text"
                                                id="text"
                                                value={type}
                                                onChange={(e) => setType(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Options:
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <TextField
                                                label="About"
                                                variant="outlined"
                                                className="textfield"
                                                type="text"
                                                id="text"
                                                value={options}
                                                onChange={(e) => setOptions(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Keywords:
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            {textInputs.map((input, index) => (
                                                <div key={input.id} style={{ marginBottom: '10px' }}>
                                                    <input
                                                        type="text"
                                                        value={input.value}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        style={{ marginRight: '10px' }}
                                                    />
                                                    {index === textInputs.length - 1 && (
                                                        <button onClick={addInputChange} style={{ marginRight: '5px', backgroundColor: 'green', color: '#fff', padding: '5px 10px', borderRadius: '2px' }}>+</button>
                                                    )}
                                                    {textInputs.length > 1 && (
                                                        <button onClick={() => removeInputChange(index)}
                                                            style={{ backgroundColor: 'red', color: '#fff', padding: '5px 10px', borderRadius: '2px' }}
                                                        >
                                                            -
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div >
                                        <label
                                            htmlFor="fmetaTitle"
                                            className="text-sm font-medium text-default-900"
                                            style={{ fontWeight: "600", color: "#000066" }}
                                        >
                                            Upload Series Image:
                                        </label>
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            {imageInputs.map((input, index) => (
                                                <div key={input.id} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <input
                                                        type="file"
                                                        onChange={(e) => handleImageChange(index, e)}
                                                        style={{ marginRight: '10px' }}
                                                    />
                                                    {input.url && <img src={input.url} alt="Selected" style={{ width: '60px', height: '60px', marginRight: '10px' }} />}
                                                    {index === imageInputs.length - 1 && (
                                                        <button onClick={addInput} style={{ marginRight: '5px', backgroundColor: 'green', color: '#fff', padding: '5px 10px', borderRadius: '2px' }}>+</button>
                                                    )}
                                                    {imageInputs.length > 1 && (
                                                        <button onClick={() => removeInput(index)} style={{ backgroundColor: 'red', color: '#fff', padding: '5px 10px', borderRadius: '2px' }}>-</button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid md:grid-cols-3 gap-2">
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={recommends}
                                                    onChange={(e) => setRecommends(e.target.checked)}
                                                />

                                                }
                                                label={
                                                    <Typography sx={{ color: '#4D44B5' }}>
                                                        Recommends</Typography>
                                                }

                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <Switch {...label} checked={multi ? true : false} onChange={() => { setMulti(!multi); SetSingle(false) }} />
                                            <span style={{ color: '#4D44B5', fontWeight: '500' }}>Multi</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <Switch {...label} checked={single ? true : false} onChange={() => { setMulti(false); SetSingle(!single) }} />
                                            <span style={{ color: '#4D44B5', fontWeight: '500' }}>Single</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox
                                                    value={hotProducts}
                                                    checked={hotProducts ? true : false}
                                                    onChange={(e) => setHotProducts(e.target.checked)}
                                                />

                                                }
                                                label={
                                                    <Typography sx={{ color: '#4D44B5' }}>
                                                        Hot Products</Typography>
                                                }

                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox

                                                    value={trending}
                                                    checked={trending ? true : false}
                                                    onChange={(e) => setTrending(e.target.checked)}
                                                />

                                                }
                                                label={
                                                    <Typography sx={{ color: '#4D44B5' }}>
                                                        Trending</Typography>
                                                }

                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="form-flex">
                                        <div
                                            className="textfieldcontainer"
                                            style={{ width: "80%" }}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox

                                                    value={offer}
                                                    checked={offer ? true : false}
                                                    onChange={(e) => setOffer(e.target.checked)}

                                                />

                                                }
                                                label={
                                                    <Typography sx={{ color: '#4D44B5' }}>
                                                        offer</Typography>
                                                }

                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-5 border-t border-dashed border-default-200">
                        {
                            packSizes.map((indPack, index) =>
                                <>
                                    <div style={{ marginTop: '50px' }}>
                                        <h1 style={{ textAlign: "center", fontWeight: "bold", color: "#4D44B5", fontSize: "1rem" }}>
                                            Pack Size-{index + 1}
                                        </h1>
                                    </div>
                                    <div className="grid md:grid-cols-2 gap-4">
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <label className="text-sm font-medium" style={{ fontWeight: "600", color: "#000066" }}>
                                                    Pack Size:
                                                </label>
                                                <div className="textfieldcontainer" style={{ width: "80%" }}>
                                                    <TextField
                                                        label="Pack Size"
                                                        variant="outlined"
                                                        value={indPack.packSize}
                                                        onChange={(e) => handlePackChange(index, 'packSize', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <label
                                                    htmlFor="fName"
                                                    className="text-sm font-medium text-default-900"
                                                    style={{ fontWeight: "600", color: "#000066" }}
                                                >
                                                    ImgUrl :
                                                </label>
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                > <input
                                                        type="file"
                                                        onChange={(e) => handleImage(e, index)}
                                                        style={{ marginRight: '10px' }}
                                                    />
                                                </div>
                                            </div>
                                            {indPack.imgUrl &&
                                                <img src={indPack.imgUrl} alt="Selected" style={{ width: '60px', height: '60px', marginRight: '10px' }} />
                                            }
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <label
                                                    htmlFor="fName"
                                                    className="text-sm font-medium text-default-900"
                                                    style={{ fontWeight: "600", color: "#000066" }}
                                                >
                                                    Cost Price :
                                                </label>
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                >
                                                    <TextField
                                                        label="Cost Price"
                                                        variant="outlined"
                                                        className={
                                                            nameError !== ""
                                                                ? "textfield error_class"
                                                                : "textfield"
                                                        }
                                                        type="text"
                                                        id="text"
                                                        value={indPack.costPrc}
                                                        onChange={(e) => handlePackChange(index, 'costPrc', e.target.value)}
                                                    // className={classes.textfield}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <label
                                                    htmlFor="fName"
                                                    className="text-sm font-medium text-default-900"
                                                    style={{ fontWeight: "600", color: "#000066" }}
                                                >
                                                    GST Cost :
                                                </label>
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                >
                                                    <TextField
                                                        label="GST Cost"
                                                        variant="outlined"
                                                        className={
                                                            nameError !== ""
                                                                ? "textfield error_class"
                                                                : "textfield"
                                                        }
                                                        type="text"
                                                        id="text"
                                                        value={indPack.gstCost}
                                                        onChange={(e) => handlePackChange(index, 'gstCost', e.target.value)}
                                                    // className={classes.textfield}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <label
                                                    htmlFor="fName"
                                                    className="text-sm font-medium text-default-900"
                                                    style={{ fontWeight: "600", color: "#000066" }}
                                                >
                                                    Sell Price :
                                                </label>
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                >
                                                    <TextField
                                                        label="Sell Price"
                                                        variant="outlined"
                                                        className={
                                                            nameError !== ""
                                                                ? "textfield error_class"
                                                                : "textfield"
                                                        }
                                                        type="text"
                                                        id="text"
                                                        value={indPack.sellingPrice}
                                                        onChange={(e) => handlePackChange(index, 'sellingPrice', e.target.value)}
                                                    // className={classes.textfield}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <label
                                                    htmlFor="fName"
                                                    className="text-sm font-medium text-default-900"
                                                    style={{ fontWeight: "600", color: "#000066" }}
                                                >
                                                    Gst Sell  :
                                                </label>
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                >
                                                    <TextField
                                                        label="Gst Sell P"
                                                        variant="outlined"
                                                        className={
                                                            nameError !== ""
                                                                ? "textfield error_class"
                                                                : "textfield"
                                                        }
                                                        type="text"
                                                        id="text"
                                                        value={indPack.gstSell}
                                                        onChange={(e) => handlePackChange(index, 'gstSell', e.target.value)}
                                                    // className={classes.textfield}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <label
                                                    htmlFor="fName"
                                                    className="text-sm font-medium text-default-900"
                                                    style={{ fontWeight: "600", color: "#000066" }}
                                                >
                                                    MRP :
                                                </label>
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                >
                                                    <TextField
                                                        label="MRP"
                                                        variant="outlined"
                                                        className={
                                                            nameError !== ""
                                                                ? "textfield error_class"
                                                                : "textfield"
                                                        }
                                                        type="text"
                                                        id="text"
                                                        value={indPack.mrp}
                                                        onChange={(e) => handlePackChange(index, 'mrp', e.target.value)}
                                                    // className={classes.textfield}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                >
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={indPack.outofstock}
                                                            onChange={(e) => handlePackChange(index, 'outofstock', e.target.checked)}
                                                        />

                                                        }
                                                        label={
                                                            <Typography sx={{ color: '#4D44B5' }}>
                                                                Out Of Stock</Typography>
                                                        }

                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                >
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={indPack.prime}
                                                            onChange={(e) => handlePackChange(index, 'prime', e.target.checked)}

                                                        />

                                                        }
                                                        label={
                                                            <Typography sx={{ color: '#4D44B5' }}>
                                                                Prime
                                                            </Typography>
                                                        }

                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                >
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={indPack.stockAuto}
                                                            onChange={(e) => handlePackChange(index, 'stockAuto', !indPack.stockAuto)}
                                                        />

                                                        }
                                                        label={
                                                            <Typography sx={{ color: '#4D44B5' }}>
                                                                StockAutoUpdate</Typography>
                                                        }

                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <div className="form-flex">
                                                <div
                                                    className="textfieldcontainer"
                                                    style={{ width: "80%" }}
                                                >
                                                    <Button
                                                        sx={{
                                                            backgroundColor: '#4D44B5',
                                                            border: '2px solid white',
                                                            color: 'white',
                                                            '&:hover': {
                                                                backgroundColor: '#3a3a6b', // Slightly darker color on hover
                                                                borderColor: 'white',
                                                            },
                                                        }}
                                                        onClick={addNewPackSize}
                                                    >
                                                        Add more
                                                    </Button>
                                                    {
                                                        packSizes.length > 1 &&
                                                        <Button
                                                            sx={{
                                                                backgroundColor: '#e15631',
                                                                border: '2px solid white',
                                                                color: 'white',
                                                                '&:hover': {
                                                                    backgroundColor: 'red', // Slightly darker color on hover
                                                                    borderColor: 'white',
                                                                },
                                                            }}
                                                            onClick={deletePackSize}
                                                        >
                                                            Delete
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    <div
                        className="flex items-center gap-2 justify-end"
                        style={{ marginBottom: "60px" }}
                    >
                        <button
                            style={{ background: "#ef4444", outline: "none", border: "none" }}
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                        >
                            <i className="ph-bold ph-x text-lg" /> Reset
                        </button>
                        <button
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                            style={{ background: "#4D44B5", outline: "none", border: "none" }}
                            onClick={() => handleProduct()}
                        >
                            <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                            Submit
                        </button>
                    </div>
                </div>
            </div >
        </>
    );
};

export default EditProduct;
