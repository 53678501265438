import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import Switch from "@mui/material/Switch";
import { Baseurl } from "../../config/BaseUrl";
import {
  subscriptionImages,
  subscriptionPost,
  validateSubscriptionSlugUrl,
} from "../../redux/subscription/subscriptionSlice";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const Addsubscription = () => {
  const dispatch = useDispatch();

  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { categoryList } = useSelector((store) => store.category);
  const { subCategoryList } = useSelector((store) => store.subCategory);
  const { brandtotal } = useSelector((store) => store.brand);
  const {
    mobileimage,
    desktopimage,
    issubscriptionmobileimageLoading,
    issubscriptiondeskimageLoading,
  } = useSelector((store) => store.subscriptions);

  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [filterSubCategory, setfilterSubCategory] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [brandId, setBrandId] = useState("");
  const [brand, setBrand] = useState("");
  const [about, setAbout] = useState("");
  const [ingradient, setIngradient] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [productinfo, setProductinfo] = useState("");
  const [type, setType] = useState("");
  const [vipPrice, setvipPrice] = useState("");
  const [gstcost, setGstcost] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [CostPrc, setCostPrc] = useState("");
  const [SellingPrice, setSellingPrice] = useState("");
  const [GstSellPrc, setGstSellPrc] = useState("");
  const [Mrp, setMrp] = useState("");

  const selectCategory = (evt) => {
    const value = evt.target.value;
    setCategoryid(value);
    const textValue = categoryList.find((indcat) => indcat._id === value);
    if (textValue) {
      setCategory(textValue);
    }
  };

  useEffect(() => {
    const filterSubCategorydata = subCategoryList.filter(
      (indSubCat) => indSubCat.categoryId === categoryid
    );
    if (filterSubCategorydata) {
      setfilterSubCategory(filterSubCategorydata);
    }
  }, [categoryid]);

  const handleSubCategory = (evt) => {
    const value = evt.target.value;
    setSubCategoryId(value);
    const textValue = subCategoryList.find((indcat) => indcat._id === value);
    if (textValue) {
      setSubCategory(textValue);
    }
  };

  const selectBrand = (e) => {
    const value = e.target.value;
    setBrandId(value);
    const textValue = brandtotal.find((indcat) => indcat._id === value);
    if (textValue) {
      setBrand(textValue);
    }
  };

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setErrorcolor("green");
    setCheckSlugUrl("");
    setError("Subscription available");
    const subscSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(subscSlug);

    if (subscSlug !== "") {
      let responce = await dispatch(validateSubscriptionSlugUrl(subscSlug));
      if (responce.payload.success) {
        setError("Subscription already exist");
        setErrorcolor("red");
      } else if (subscSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (subscSlug.length >= 3) {
        setErrorcolor("green");
        setError("Subscription available");
        setCheckSlugUrl(subscSlug);
      }
    }
  };

  const SubscriptionImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subscriptionImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handlesubmit = async (e) => {
    if (checkslugUrl !== "") {
      const discount = Math.round(((Mrp - SellingPrice) * 100) / Mrp);
      const vipdiscount = Math.round(((Mrp - vipPrice) * 100) / Mrp);
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        Category: category.name,
        CatId: categoryid,
        categoryUrl: category.slugUrl,
        SubCat: subCategory.name,
        SubCatId: subCategoryId,
        SubCategoryUrl: subCategory.slugUrl,
        Brand: brand.name,
        BrandId: brandId,
        BrandUrl: brand.slugUrl,
        KeyWords: metakeyword,
        Description: metadesc,
        Title: metatitle,
        About: about,
        Ingredient: ingradient,
        ProductInfo: productinfo,

        Type: type,

        Pcb1: 1,
        Pcb2: 2,
        Pcb3: 3,
        ImgUrlMbl: mobileimage,
        ImgUrlDesk: desktopimage,
        CostPrc: CostPrc,
        GstCost: gstcost,
        SellingPrice: SellingPrice,
        VipSellingPrice: vipPrice,
        GstSellPrc: GstSellPrc,
        Mrp: Mrp,
        Discount: discount,
        VipDiscount: vipdiscount,
      };
      const data = await dispatch(subscriptionPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Subscription Added");
          setName("");
          setCategory(null);
          setCategoryid(null);
          setBrand(null);
          setSlugUrl("");
          setAbout("");
          setType("");
          setProductinfo("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setCostPrc("");
          setSellingPrice("");
          setGstSellPrc("");
          setMrp("");
          setGstcost("");
          setSellingPrice("");
        } else {
          alert(data.message);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Subscription
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Category:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="super-category-label"
                          className="textfield"
                          label="Super Category"
                          id="demo-simple-select"
                          inputProps={{ style: { textAlign: "center" } }}
                          value={categoryList._id}
                          name={categoryList.name}
                          onChange={(e) => selectCategory(e)}
                        >
                          {categoryList &&
                            categoryList.map((supcat, index) => (
                              <MenuItem key={index} value={supcat._id}>
                                {supcat.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Sub Category:
                    </label>
                    <div style={{ width: "80%" }}>
                      <FormControl
                        fullWidth
                        style={{ marginTop: "12px" }}
                        className="textfield"
                      >
                        <InputLabel
                          id="demo-simple-Case-Type"
                          className="inputcenter"
                        >
                          Select Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-Case-Type"
                          id="demo-simple-select"
                          label="Select Sub Category"
                          onChange={(e) => handleSubCategory(e)}
                          style={{ height: "35px" }}
                          inputProps={{ style: { textAlign: "center" } }}
                        >
                          {filterSubCategory.map((test, index) => (
                            <MenuItem value={test._id} key={index}>
                              {test.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Brand:
                    </label>
                    <div style={{ width: "80%" }}>
                      <FormControl
                        fullWidth
                        style={{ marginTop: "12px" }}
                        className="textfield"
                      >
                        <InputLabel
                          id="demo-simple-Case-Type"
                          className="inputcenter"
                        >
                          Select Brand
                        </InputLabel>
                        <Select
                          labelId="demo-simple-Case-Type"
                          id="demo-simple-select"
                          label="Select Category"
                          style={{ height: "35px" }}
                          inputProps={{ style: { textAlign: "center" } }}
                          //   value={caseid}
                          onChange={(e) => selectBrand(e)}
                          value={brandtotal.name}
                          name={brandtotal.name}
                        >
                          {brandtotal.map((indCat, index) => (
                            <MenuItem value={indCat._id} key={index}>
                              {indCat.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Name"
                        variant="outlined"
                        className="textfield"
                        // className={
                        //   nameError !== ""
                        //     ? "textfield error_class"
                        //     : "textfield"
                        // }
                        type="text"
                        id="text"
                        value={name}
                        onChange={(e) => verifyslugurl(e)}
                        // className={classes.textfield}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      About:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter About"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Ingredient*:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Ingradient"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={ingradient}
                        onChange={(e) => setIngradient(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Descriptrion :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Meta Descriptrion"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Title :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Meta Title"
                        variant="outlined"
                        className="textfield"
                        rows={2}
                        type="text"
                        id="text"
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Keyword :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Meta Keyword"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        multiline
                        rows={2}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div>
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Desktop Image:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <div
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="file"
                          onChange={SubscriptionImageChange}
                          style={{ marginRight: "10px" }}
                        />
                        {issubscriptiondeskimageLoading ? (
                          <></>
                        ) : (
                          <img
                            src={desktopimage}
                            alt="Selected"
                            style={{
                              width: "60px",
                              height: "60px",
                              marginRight: "10px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Product Info:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Product Info"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={productinfo}
                        onChange={(e) => setProductinfo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      GST Cost:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Product Type"
                        variant="outlined"
                        className="textfield"
                        type="number"
                        id="text"
                        value={gstcost}
                        onChange={(e) => setGstcost(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Cost Prcice:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Cost Price"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={CostPrc}
                        required
                        onChange={(e) =>
                          setCostPrc(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Sell Prcice:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Cost Price"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={SellingPrice}
                        required
                        onChange={(e) =>
                          setSellingPrice(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      VIP Sell Price:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Cost Price"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={vipPrice}
                        required
                        onChange={(e) =>
                          setvipPrice(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      GST Sell Price:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Cost Price"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={GstSellPrc}
                        required
                        onChange={(e) =>
                          setGstSellPrc(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      MRP:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Cost Price"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={Mrp}
                        required
                        onChange={(e) =>
                          setMrp(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={() => handlesubmit()}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addsubscription;
