import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import Switch from "@mui/material/Switch";
import { Baseurl } from "../../../config/BaseUrl";
import {
  subCatBannerDesktopImages,
  subCatBannerMobileImages,
  subCatBannerPost,
  updateSubCatBannerDeskImage,
  updateSubCatBannerMobileImage,
  validateSubCatBannerUrl,
} from "../../../redux/subCatBanner/subCatBannerSlice";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const AddSubcatBanner = () => {
  const dispatch = useDispatch();

  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { categoryList } = useSelector((store) => store.category);
  const { subCategoryList } = useSelector((store) => store.subCategory);
  const {
    mobileimage,
    desktopimage,
    mobileimageLoading,
    desktopimageLoading,
    subcatbannertotal,
  } = useSelector((store) => store.Subcatbanner);

  // Here from this states
  const [superCategory, setSuperCategory] = useState("");
  const [superCategoryId, setSuperCategoryId] = useState("");
  const [catbySupercat, setCatbySupercat] = useState([]);

  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [filterSubCategory, setfilterSubCategory] = useState([]);

  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const [checked, setChecked] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [Url, setUrl] = useState("");
  const [checkurl, setCheckurl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const handleSuperCatgory = (e) => {
    setSuperCategoryId(e.target.value);
    const getSuperCategoryName = superCatTotal.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getSuperCategoryName) {
      setSuperCategory(getSuperCategoryName);
    }
  };

  useEffect(() => {
    const catbysupercat = categoryList.filter(
      (cat) => cat.superCategoryId === superCategoryId
    );
    setCatbySupercat(catbysupercat);
  }, [superCategoryId, categoryList]);

  const handleChangeCategory = (evt) => {
    const value = evt.target.value;
    setCategoryId(value);
    const textValue = categoryList.find((indcat) => indcat._id === value);
    if (textValue) {
      setCategory(textValue);
    }
  };

  useEffect(() => {
    const filterSubCategorydata = subCategoryList.filter(
      (indSubCat) => indSubCat.categoryId === categoryId
    );
    if (filterSubCategorydata) {
      setfilterSubCategory(filterSubCategorydata);
    }
  }, [categoryId]);

  const handleSubCategory = (evt) => {
    const value = evt.target.value;
    setSubCategoryId(value);
    const textValue = subCategoryList.find((indcat) => indcat._id === value);
    if (textValue) {
      setSubCategory(textValue);
    }
  };

  const desktopChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCatBannerDesktopImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const mobileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCatBannerMobileImages({ mobileImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const verifyurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckurl("");
    const subcatbannerurl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setUrl(subcatbannerurl);

    if (subcatbannerurl !== "") {
      let responce = await dispatch(validateSubCatBannerUrl(subcatbannerurl));
      if (responce.payload.success) {
        setError("subcat banner already exist");
        setErrorcolor("red");
      } else if (subcatbannerurl.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckurl("");
      } else if (subcatbannerurl.length >= 3) {
        setErrorcolor(" ");
        setError("");
        setCheckurl(subcatbannerurl);
      }
    }
  };

  const handleProduct = async () => {
    const formData = {
      name: name,
      URL: Url,
      superCategoryId: superCategoryId,
      superCategory: superCategory.name,
      superCategoryUrl: superCategory.slugUrl,
      Category: category.name,
      CatId: categoryId,
      categoryUrl: category.slugUrl,
      SubCat: subCategory.name,
      SubCatId: subCategoryId,
      subCategoryUrl: subCategory.slugUrl,
      DeskImg: desktopimage,
      MblImg: mobileimage,
    };
    console.log(formData, "formData");

    const response = await dispatch(subCatBannerPost(formData));
    if (response.payload.success) {
      alert(`Sub Category Banner With Name:${name} is created`);
      setName("");
      setMetaTitle("");
      setMetaDesc("");
      const formData = {
        status: true,
        image: "",
      };
      await dispatch(updateSubCatBannerMobileImage(formData));
      await dispatch(updateSubCatBannerDeskImage(formData));
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Subcat Banner
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Super Category:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <FormControl
                          fullWidth
                          style={{ marginTop: "12px" }}
                          className="textfield"
                        >
                          <InputLabel
                            id="demo-simple-Case-Type"
                            className="inputcenter"
                          >
                            Select Super Category
                          </InputLabel>
                          <Select
                            labelId="super-category-label"
                            className="textfield"
                            label="Super Category"
                            id="demo-simple-select"
                            inputProps={{ style: { textAlign: "center" } }}
                            value={superCategoryId}
                            onChange={(e) => handleSuperCatgory(e)}
                          >
                            {superCatTotal &&
                              superCatTotal.map((supcat, index) => (
                                <MenuItem key={index} value={supcat._id}>
                                  {supcat.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Category:
                    </label>
                    <div style={{ width: "80%" }}>
                      <FormControl
                        fullWidth
                        style={{ marginTop: "12px" }}
                        className="textfield"
                      >
                        <InputLabel
                          id="demo-simple-Case-Type"
                          className="inputcenter"
                        >
                          Select Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-Case-Type"
                          id="demo-simple-select"
                          // value={caseType}
                          label="Select Category"
                          onChange={(e) => handleChangeCategory(e)}
                          style={{ height: "35px" }}
                          inputProps={{ style: { textAlign: "center" } }}
                        >
                          {catbySupercat.map((indCat, index) => (
                            <MenuItem value={indCat._id} key={index}>
                              {indCat.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Sub Category:
                    </label>
                    <div style={{ width: "80%" }}>
                      <FormControl
                        fullWidth
                        style={{ marginTop: "12px" }}
                        className="textfield"
                      >
                        <InputLabel
                          id="demo-simple-Case-Type"
                          className="inputcenter"
                        >
                          Select Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-Case-Type"
                          id="demo-simple-select"
                          // value={caseType}
                          label="Select Sub Category"
                          onChange={(e) => handleSubCategory(e)}
                          style={{ height: "35px" }}
                          inputProps={{ style: { textAlign: "center" } }}
                        >
                          {filterSubCategory.map((test, index) => (
                            <MenuItem value={test._id} key={index}>
                              {test.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Name"
                        variant="outlined"
                        className={
                          nameError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={name}
                        onChange={(e) => verifyurl(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="vehicleWeight"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Desktop Image :
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="file"
                        className={
                          "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                        }
                        onChange={desktopChange}
                      />
                    </div>
                  </div>
                  {desktopimageLoading ? (
                    <></>
                  ) : (
                    <div>
                      <img
                        src={desktopimage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    </div>
                  )}
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="vehicleWeight"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Mobile Image :
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="file"
                        className={
                          "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                        }
                        onChange={mobileChange}
                      />
                    </div>
                  </div>
                  {mobileimageLoading ? (
                    <></>
                  ) : (
                    <div>
                      <img
                        src={mobileimage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={() => handleProduct()}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSubcatBanner;
