import React, { useEffect, useState } from "react";
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import { productDelete } from "../../redux/Product/ProductSlice";
import axios from "axios";
const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const DeliveredOrder = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { bookedOrder } = useSelector((store) => store.order)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState('')
    const [orders, setOrders] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const url = `https://kiranaworldadminapi.kiranaworld.in/api/v1/order/orderrecieved`;
            const fetchOrder = await axios.get(url);
            if (fetchOrder.data.success === true) {
                const orderTotal = fetchOrder.data.orders;
                setOrders(orderTotal.reverse());
            }
        };
        fetchData();
    }, []);

    const showModal = (product) => {
        setIsDeleteOpen(true);
        setSelectedProduct(product)
    };

    const handleOk = () => {
        setIsDeleteOpen(false);
    };

    const handleCancel = () => {
        setIsDeleteOpen(false);
    };
    const handleDelete = async () => {
        setIsDeleteOpen(false);
        await dispatch(productDelete(selectedProduct._Id))
    }
    console.log(orders[0], 'orders[0]');

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-3 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                    </div>
                </div>

                <div className="grid grid-cols-1">
                    <div
                        className="shadow rounded-lg bg-white dark:bg-default-50"
                        style={{ marginBottom: "80px" }}
                    >
                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden  table-striped">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                No
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                                            >
                                                Client Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                                            >
                                                Mobile
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Address
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Payment Mode
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                TotalAmount
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                createdAt
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders && orders.map((indOrder, index) =>
                                            <tr style={{ background: index % 2 === 0 ? "#eee" : "#fff" }}>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                                                >
                                                    {index + 1}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                                                    style={{ color: 'green', cursor: 'pointer' }}
                                                >
                                                    {indOrder.ClientName}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                                                >
                                                    {indOrder.Mobile}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                                >
                                                    {indOrder.Email}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                                >
                                                    {indOrder.Address}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                                >
                                                    {indOrder.PaymentMode}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                                >
                                                    {indOrder.TotalAmount}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                                                >
                                                    {indOrder.createdAt.slice(0, 10)}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title="Basic Modal"
                    open={isDeleteOpen}
                    onCancel={handleCancel}
                    footer={[
                        <Button style={{ padding: '10px 15px', color: '#fff', backgroundColor: '#FF0000' }} onClick={() => setIsDeleteOpen(false)}>Cancel</Button>,
                        <Button style={{ padding: '10px 15px', color: '#fff', backgroundColor: '#04681e' }} onClick={() => handleDelete()}>Confirm</Button>
                    ]}
                >
                    <p>
                        Do you want to delete Product With ProductId: <span style={{ color: 'red' }}>{selectedProduct.ProductId}</span>
                    </p>
                </Modal>
            </div>
        </>
    );
};

export default DeliveredOrder;
