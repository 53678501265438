import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment/moment";
import { Button } from "@mui/material";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import "../../Cart.css";
import BeatLoader from "react-spinners/BeatLoader";

const OrderDetails = () => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [temploading, setTempLoading] = useState(true);
    const [productall, setProductall] = useState([]);

    const [products, setProducts] = useState([]);
    const [orderinfo, setOrderinfo] = useState("");
    const [cartamountDetails, setCartamountDetails] = useState("");
    const [editBoxtShow, setEditBoxtShow] = useState(false);
    const [confnewQuentity, setConfnewQuentity] = useState(1);

    const [supercategory, setSupercategory] = useState("");
    const [supercategoryid, setSupercategoryid] = useState("");
    const [subCat, setSubcat] = useState([]);
    const [subcategory, setSubcategory] = useState("");
    const [subcategoryid, setSubcategoryid] = useState("");
    const [category, setCategory] = useState("");

    const [categoryid, setCategoryid] = useState("");
    const [catbysuper, setCatbysuper] = useState([]);

    const [subcatByProducts, setSubcatByProducts] = useState([]);
    const [productName, setProductName] = useState("");
    const [productId, setProductId] = useState("");
    const [selectproduct, setSelectProduct] = useState("");
    const [selectPackSize, setSelectPacksize] = useState("");

    const [productsByPack, setProductsByPack] = useState([]);
    const [packName, setPackName] = useState("");
    const [packId, setPackId] = useState("");
    const [packIndexs, setPackIndexs] = useState("");
    const supercat = [
        { id: "63d767855658519287238b63", name: "Supermart" },
        { id: "63d8da2bd405398b770b508f", name: "Non-veg" },
        { id: "63d8da4ad405398b770b50aa", name: "Vegetables & fruits" },
    ];

    useEffect(() => {
        const orderId = params.orderid;

        const fetchData = async () => {
            try {
                const url = `https://kiranaworldadminapi.kiranaworld.in/api/v1/order/singleorderbyclient/${orderId}`;
                const orderFetch = await axios.get(url);

                try {
                    if (orderFetch.data.success) {
                        const orderdetails = orderFetch.data.order;
                        setOrderinfo(orderdetails);
                        setProducts(orderdetails.OrderProducts);
                    }
                } catch (error) { }
            } catch (error) { }
        };
        fetchData();
    }, [params.orderid]);

    useEffect(() => {
        let cartTotalAmountN = 0;
        let totalAmount = 0;
        let netPayableN = 0;
        let cartTotalMrpN = 0;
        let cartTotalPriceN = 0;
        let totalMrp = 0;
        let totalprice = 0;
        let logisticsAmountN = 40;
        const totalitem = products.filter((order) => order.Status === true);

        for (let i = 0; i < totalitem.length; i++) {
            cartTotalAmountN = cartTotalAmountN + totalitem[i].TotalAmount;

            cartTotalMrpN = cartTotalMrpN + totalitem[i].TotalMrp * totalitem[i].Qty;

            totalMrp = totalMrp + totalitem[i].Mrp * totalitem[i].Qty;

            totalprice = totalprice + totalitem[i].Price * totalitem[i].Qty;

            cartTotalPriceN =
                cartTotalPriceN + totalitem[i].TotalPrice * totalitem[i].Qty;
        }

        if (cartTotalAmountN >= 500) {
            logisticsAmountN = 0;
        } else if (
            orderinfo.CurrentStatus === "Paid" ||
            orderinfo.GrandTotal === 0
        ) {
            logisticsAmountN = 40;
        } else {
            logisticsAmountN = 40;
        }
        const coupondata = orderinfo.couponDetails;
        cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
        totalAmount = cartTotalAmountN - logisticsAmountN;
        netPayableN = totalAmount - orderinfo.wallet;

        const cartAmountsForm = {
            cartTotalAmount: cartTotalAmountN,
            netPayable: netPayableN,
            cartTotalMrp: cartTotalMrpN,
            cartTotalPrice: cartTotalPriceN,
            logisticsAmount: logisticsAmountN,
            totalAmount: cartTotalAmountN,
            totalMrp: totalMrp,
            totalprice: totalprice,
        };

        setCartamountDetails(cartAmountsForm);
    }, [orderinfo, products]);

    const selectsupCategory = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setSupercategoryid(value);
        setSupercategory(text);
    };
    const selectCategory = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setCategoryid(value);
        setCategory(text);
    };


    const selectProduct = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setProductId(value);
        setProductName(text);
    };
    const selectPacksize = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        const currindex = Number(-1) + Number(index);
        setPackId(value);
        setPackName(text);
        setPackIndexs(currindex);
    };
    return (
        <>
            <Fragment>
                <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                    <div className="content-wrapper" style={{ marginBottom: '80px' }}>
                        <div className="card me-2 ms-2">
                            <section className="h-100" style={{ backgroundColor: "#eee" }}>
                                <div className="container h-100 py-2">
                                    <div className="row d-flex justify-content-center align-items-center h-100">
                                        <div className="col-12 m-0 p-0 h-75">
                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                <h3 className="fw-normal mb-0 text-black ms-2">
                                                    Order Cart
                                                </h3>
                                                <div></div>
                                            </div>
                                            {products &&
                                                products
                                                    .filter((order) => order.Status === true)
                                                    .map((cartPro, cartindex) => (
                                                        <div
                                                            className="card rounded-3 mb-1"
                                                            key={cartPro._id}
                                                            style={{ backgroundColor: '#fff', width: '78vw' }}
                                                        >
                                                            <div className="container p-4 mt-0 ">
                                                                <div className="row  total-div">
                                                                    <div className=" col-md-2 col-lg-3 col-xl-2 sub_child ">
                                                                        <img
                                                                            style={{ width: '180px' }}
                                                                            src={cartPro.ImgUrl}
                                                                            className="img-fluid blur-up  "
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-xl-3  Product-info">
                                                                        <p className="lead fw-normal m-0 product-font "
                                                                        >
                                                                            <div style={{ fontSize: "16px", width: '180px', flexWrap: 'wrap' }}>
                                                                                {cartPro.ItemName}
                                                                            </div>
                                                                            <div
                                                                                style={{ fontSize: "16px", width: '180px', flexWrap: 'wrap' }}
                                                                                className="mt-2"
                                                                            >
                                                                                Pack Size : {cartPro.PackSize}
                                                                            </div>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-2 col-xl-3  price-ifo">
                                                                        <h5 className="price-margin">
                                                                            Price : ₹ {cartPro.Price}
                                                                        </h5>
                                                                        <h5 className="price-margin">
                                                                            MRP : ₹ {cartPro.Mrp}
                                                                        </h5>
                                                                        <h5 style={{ color: "#0e947a" }}>
                                                                            Total Amount : ₹ {cartPro.TotalAmount}
                                                                        </h5>
                                                                    </div>
                                                                    {orderinfo.Status === 5 &&
                                                                        orderinfo.Status === 0 ? (
                                                                        <>
                                                                            <div className="col-md-1 col-lg-1 col-xl-1 text-end  Remove">
                                                                                <a
                                                                                    className="text-danger "
                                                                                    style={{ cursor: "pointer" }}
                                                                                >
                                                                                    <i className="fas fa-trash fa-lg" />{" "}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="mobileView text-danger"
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div
                                                                                className="col-md-1 col-lg-1 col-xl-1 text-end  Remove"
                                                                            >
                                                                                <a
                                                                                    className="text-danger "
                                                                                    style={{ cursor: "pointer" }}
                                                                                >
                                                                                    <i className="fas fa-trash fa-lg" />{" "}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="mobileView text-danger"
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'flex-end'}}>
                                                        <p>Total Amount:</p>
                                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default OrderDetails;
