import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookedOrder from "./order/BookedOrder";

const Home = () => {
  const { subCategoryList } = useSelector((store) => store.subCategory)
  const { categoryList } = useSelector((store) => store.category)
  const { productList } = useSelector((store) => store.grocery)

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-6 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
            <ol
              aria-label="Breadcrumb"
              className="hidden md:flex items-center whitespace-nowrap min-w-0 gap-2"
            >
              <li className="text-sm">
                <a
                  className="flex items-center gap-2 align-middle text-default-800 font-medium transition-all hover:text-primary-500"
                  href="javascript:void(0)"
                >
                  KisanGhar
                  <FaChevronRight />
                </a>
              </li>
              <li className="text-sm">
                <a
                  className="flex items-center gap-2 align-middle text-default-800 font-medium transition-all hover:text-primary-500"
                  href="javascript:void(0)"
                >
                  Admin
                  <FaChevronRight />
                </a>
              </li>
              <li
                aria-current="page"
                className="text-sm font-medium text-primary-600 truncate hover:text-primary-500"
              >
                Dashboard
              </li>
            </ol>
          </div>
          <div
            className="grid md:grid-cols-2 xl:grid-cols-4 gap-6"
            style={{ marginBottom: "60px" }}
          >
            <div
              className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
              style={{ backgroundColor: "#009999" }}
            >
              <div className="p-5">
                <div className="">
                  <div className="mb-3">
                    <span className="text-base font-semibold text-default-900"
                      style={{ color: "#f2f2f2", fontWeight: "600" }}
                    >
                      Total Shop :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#f2f2f2", fontWeight: "600" }}
                    >
                      5
                    </span>
                  </div>
                  <span className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80">
                    <img
                      src="assets/school-be034c54.png"
                      alt
                      className="h-16"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div
              className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
              style={{ backgroundColor: "#4d44b5" }}
            >
              <div className="p-5">
                <div className="">
                  <div className="mb-3">
                    <span
                      className="text-base font-semibold text-default-900"
                      style={{ color: "#f2f2f2" }}
                    >
                      Total Sales :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#f2f2f2", fontWeight: "600" }}
                    >
                      1750
                    </span>
                  </div>
                  <span
                    className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80"
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src="/assets/view-3d-businessman_23-2150709814.jpg"
                      alt
                      style={{
                        height: "100%",
                        width: "100%",
                        objectPosition: "center",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div
              className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
              style={{ backgroundColor: "#ffcc00" }}
            >
              <div className="p-5">
                <div className="">
                  <div className="mb-3">
                    <span className="text-base font-semibold text-default-900">
                      Total Product :{" "}
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                     
                      13
                    </span>
                  </div>
                  <span
                    className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80"
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src="/assets/16562.jpg"
                      alt
                      style={{
                        height: "100%",
                        width: "100%",
                        objectPosition: "center",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div
              className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
              style={{ backgroundColor: "#e55353" }}
            >
              <div className="p-5">
                <div className="">
                  <div className="mb-3">
                    <span
                      className="text-base font-semibold text-default-900"
                      style={{ color: "#f2f2f2" }}
                    >
                      All Order :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#f2f2f2", fontWeight: "600" }}
                    >
                      8
                    </span>
                  </div>
                  <span
                    className="bg-default-200 rounded-lg flex items-center justify-center h-20 w-20 dark:bg-default-200/80"
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src="/assets/submit.jpg"
                      alt
                      style={{
                        height: "100%",
                        width: "100%",
                        objectPosition: "center",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop:'-10vh'}}>
        <BookedOrder />
      </div>
      <ToastContainer />
    </>
  );
};

export default Home;
