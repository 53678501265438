import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../redux/Authentication/AuthSlice";
import CategoryReducer from "../redux/Category/CategorySlice";
import SubCategoryReducer from "./SubCategory/SubCategorySlice";
import groceryReducer from "./Product/ProductSlice";
import OrderReducer from "./Order/OrderSlice";
import superCategoryReducer from "./SuperCategory/SuperCategorySlice";
import BrandReducer from "./Brand/BrandSlice";
import subscriptionsReducer from "./subscription/subscriptionSlice";
import CouponsReducer from "./coupon/couponSlice";
import SlidersReducer from "./slider/sliderSlice";
import SubcatbannerReducer from "./subCatBanner/subCatBannerSlice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    superCategory: superCategoryReducer,
    category: CategoryReducer,
    subCategory: SubCategoryReducer,
    grocery: groceryReducer,
    order: OrderReducer,
    brand: BrandReducer,
    subscriptions: subscriptionsReducer,
    Coupons: CouponsReducer,
    Sliders: SlidersReducer,
    Subcatbanner: SubcatbannerReducer,
  },
});
