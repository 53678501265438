import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputAdornment, TextField } from "@mui/material";

import axios from "axios";
import { couponPost } from "../../redux/coupon/couponSlice";

const AddCoupon = () => {
  const { imageLoading, desktopImage } = useSelector(
    (store) => store.subCategory
  );

  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [couponname, setCouponname] = useState("");
  const [type, setType] = useState("");
  const [value, setValue] = useState(0);
  const [times, setTimes] = useState(0);
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [maxdiscount, setMaxdiscount] = useState(0);
  const [minamount, setMinamount] = useState(0);
  const [paymenttype, setPaymenttype] = useState("");

  const types = [
    { id: "1", name: "Percentege Discount" },
    { id: "2", name: "Fixed Discount" },
    { id: "3", name: "Free Delivery" },
  ];
  const paymenttypes = [
    { id: "1", name: "COD" },
    { id: "2", name: "Payment Getway" },
  ];

  const selectpayment = (e) => {
    const { value } = e.target.value;
    const paymenttypesName = paymenttypes.find(
      (indCat) => indCat.id === e.target.value
    );
    if (paymenttypesName) {
      setPaymenttype(paymenttypesName.name);
    }
  };

  const selecttype = (e) => {
    const value = e.target.value;
    const typesName = types.find((indCat) => indCat.id === e.target.value);
    if (typesName) {
      setType(typesName.name);
    }
  };

  const handlesubmit = async (e) => {
    const formData = {
      Title: title,
      Description: description,
      CouponName:
        couponname.charAt(0).toUpperCase() + couponname.slice(1).toLowerCase(),
      Type: type,
      Value: value,
      Times: times,
      FDate: fromdate,
      TDate: todate,
      MaxDisc: maxdiscount,
      MinAmount: minamount,
      PaymentType: paymenttype,
    };
    const data = await dispatch(couponPost(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Coupon Added");
        setTitle("");
        setDescription("");
        setCouponname("");
        setType("");
        setValue("");
        setTimes("");
        setFromdate("");
        setTodate("");
        setMaxdiscount("");
        setMinamount("");
        setPaymenttype("");
      } else {
        alert(data.message);
      }
    }
  };

  //   const deleteClick = async (e, id) => {
  //     dispatch(couponDelete(id));
  //   };

  const handleReset = () => {
    setTitle("");
    setDescription("");
    setCouponname("");
    setType("");
    setValue("");
    setTimes("");
    setFromdate("");
    setTodate("");
    setMaxdiscount("");
    setMinamount("");
    setPaymenttype("");
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Coupon
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Title :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Title"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Description :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Description"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Coupon Code :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <TextField
                        label="Enter Coupon Code"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={couponname}
                        onChange={(e) => setCouponname(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Type:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="super-category-label"
                          className="textfield"
                          label="Super Category"
                          id="demo-simple-select"
                          inputProps={{ style: { textAlign: "center" } }}
                          onChange={(e) => {
                            selecttype(e);
                          }}
                        >
                          {types &&
                            types.map((indCat, index) => (
                              <MenuItem key={index} value={indCat.id}>
                                {indCat.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Value :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Value"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Times :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Times"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={times}
                        onChange={(e) => setTimes(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      From Date :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label=""
                        variant="outlined"
                        className="textfield"
                        type="date"
                        id="text"
                        value={fromdate}
                        onChange={(e) => setFromdate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      To Date :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label=""
                        variant="outlined"
                        className="textfield"
                        type="date"
                        id="text"
                        value={todate}
                        onChange={(e) => setTodate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Maximum Discount :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Maximum Discount"
                        variant="outlined"
                        className="textfield"
                        type="number"
                        id="text"
                        value={maxdiscount}
                        onChange={(e) => setMaxdiscount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Payment Type:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="super-category-label"
                          className="textfield"
                          label="Super Category"
                          id="demo-simple-select"
                          inputProps={{ style: { textAlign: "center" } }}
                          onChange={(e) => {
                            selectpayment(e);
                          }}
                        >
                          {paymenttypes &&
                            paymenttypes.map((indCat, index) => (
                              <MenuItem key={index} value={indCat.id}>
                                {indCat.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              onClick={() => handleReset()}
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={() => handlesubmit()}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCoupon;
