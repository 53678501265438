import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
const initialState = {
  subCategoryList: [],
  subCategoryLoading: true,
  isSubCategoryAvailable: false,
  desktopImage:'',
  mobileImage:'',
  imageLoading: true,
};

export const subCategoryPost = createAsyncThunk(
  "subCategory/subCategoryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const subCategoryAll = createAsyncThunk(
  "subCategory/subCategoryAll",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const subCategoryUpdate = createAsyncThunk(
  "subCategory/subCategoryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/update/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      console.log(resp.data,"log.data");
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const subCategoryDelete = createAsyncThunk(
  "subCategory/subCategoryDelete",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/delete/${formData._id}`;
      const resp = await axios.delete(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const subCategoryImages = createAsyncThunk(
  "category/subCategoryImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcategory/uploardSubCategoryImage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("saloonimages Not create");
    }
  }
);
const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    setSubCategoryImage(state,action){
      state.imageLoading = action.payload.status;
      state.mobileImage = action.payload.image;
      state.desktopImage = action.payload.image
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(subCategoryPost.pending, (state) => {
        state.isSubCategoryAvailable = false;
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subCategoryList = [action.payload.SubCategory, ...state.subCategoryList];
        }
        state.isSubCategoryAvailable = true;
        state.subCategoryLoading = false;
      })
      .addCase(subCategoryPost.rejected, (state) => {
        state.isSubCategoryAvailable = false;
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryAll.pending, (state) => {
        state.isSubCategoryAvailable = false;
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryAll.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subCategoryList = action.payload.SubCategories
        }
        state.isSubCategoryAvailable = true;
        state.subCategoryLoading = false;
      })
      .addCase(subCategoryAll.rejected, (state) => {
        state.isSubCategoryAvailable = false;
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryUpdate.pending, (state) => {
        state.isSubCategoryAvailable = false;
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subCategoryList = state.subCategoryList.filter((subcategory) => {
            return subcategory._id !== action.payload.SubCategory._id;
          });
          state.subCategoryList = [action.payload.SubCategory, ...state.subCategoryList];
        }
        state.isSubCategoryAvailable = true;
        state.subCategoryLoading = false;
      })
      .addCase(subCategoryUpdate.rejected, (state) => {
        state.isSubCategoryAvailable = false;
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryDelete.pending, (state) => {
        state.isSubCategoryAvailable = false;
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subCategoryList = state.subCategoryList.filter((subcategory) => {
            return subcategory._id !== action.payload.SubCategory._id;
          });
        }
        state.isSubCategoryAvailable = true;
        state.subCategoryLoading = false;
      })
      .addCase(subCategoryDelete.rejected, (state) => {
        state.isSubCategoryAvailable = false;
        state.subCategoryLoading = true;
      })
      .addCase(subCategoryImages.pending, (state) => {
        state.imageLoading = true;
      })
      .addCase(subCategoryImages.fulfilled, (state, action) => {
        state.imageLoading = false;
        state.mobileImage = action.payload.mobileimages;
        state.desktopImage = action.payload.desktopImages;
      })
      .addCase(subCategoryImages.rejected, (state) => {
        state.imageLoading = true;
      })
  },
});

export const subCategoryActions = subCategorySlice.actions;
export default subCategorySlice.reducer;
