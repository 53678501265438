import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { productDelete } from "../../redux/Product/ProductSlice";
import { subscriptionDelete } from "../../redux/subscription/subscriptionSlice";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const ListSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscriptiontotal } = useSelector((store) => store.subscriptions);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const showModal = (product) => {
    setIsDeleteOpen(true);
    setSelectedProduct(product);
  };

  const handleCancel = () => {
    setIsDeleteOpen(false);
  };
  const handleDelete = async () => {
    setIsDeleteOpen(false);
    await dispatch(subscriptionDelete(selectedProduct._id));
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-3 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
            <div className="flex flex-wrap items-center justify-end py-1 px-5">
              <span
                onClick={() => navigate("/admin/addsubscription")}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#4d44b5",
                  color: "#fff",
                }}
                className="py-2 px-5 inline-flex items-center justify-center font-semibold tracking-wide align-middle duration-500 text-sm text-center bg-primary hover:bg-primary-500 text-white rounded"
              >
                <i className="ph ph-plus-circle text-lg/none me-3" /> Add Add
                Subscription
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <div
            className="shadow rounded-lg bg-white dark:bg-default-50"
            style={{ marginBottom: "80px" }}
          >
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Name
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Image
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptiontotal &&
                      subscriptiontotal.map((indProduct, index) => (
                        <tr>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                          >
                            {index + 1}
                          </td>

                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            {indProduct.name}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <img
                              src={indProduct.ImgUrlDesk}
                              alt="catimg"
                              height="50"
                              width="50"
                            />
                          </td>

                          <td
                            scope="col"
                            className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Link
                            //   to={`/admin/editProduct/${indProduct.ProductId}`}
                            >
                              <button
                                style={{
                                  backgroundColor: "#1976d2",
                                  color: "#fff",
                                  padding: "10px 20px",
                                  borderRadius: "5px",
                                }}
                              >
                                Edit
                              </button>
                            </Link>
                          </td>
                          <td
                            scope="col"
                            className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Link>
                              <button
                                onClick={() => showModal(indProduct)}
                                style={{
                                  backgroundColor: "#ff0000",
                                  color: "#fff",
                                  padding: "10px 20px",
                                  borderRadius: "5px",
                                }}
                              >
                                Delete
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Basic Modal"
          open={isDeleteOpen}
          onCancel={handleCancel}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => setIsDeleteOpen(false)}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>,
          ]}
        >
          <p>
            Do you want to delete Subscription With Name:{" "}
            <span style={{ color: "red" }}>{selectedProduct.name}</span>
          </p>
        </Modal>
      </div>
    </>
  );
};

export default ListSubscription;
