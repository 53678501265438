import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { superCategoryDelete } from "../../redux/SuperCategory/SuperCategorySlice";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const SuperCategoryList = () => {
  const [filterData, setFilterData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { superCatTotal } = useSelector((store) => store.superCategory);

  useEffect(() => {
    const data = superCatTotal;
    setFilterData(data);
  }, [superCatTotal]);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const showModal = (product) => {
    setIsDeleteOpen(true);
    setSelectedProduct(product);
  };

  const handleOk = () => {
    setIsDeleteOpen(false);
  };

  const handleCancel = () => {
    setIsDeleteOpen(false);
  };
  const handleDelete = async () => {
    await dispatch(superCategoryDelete(selectedProduct._id));
    setIsDeleteOpen(false);
  };
  return (
    <Fragment>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-3 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
            <div className="flex flex-wrap items-center justify-end py-1 px-5">
              <span
                onClick={() => navigate("/admin/addsupersategory")}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#4d44b5",
                  color: "#fff",
                }}
                className="py-2 px-5 inline-flex items-center justify-center font-semibold tracking-wide align-middle duration-500 text-sm text-center bg-primary hover:bg-primary-500 text-white rounded"
              >
                <i className="ph ph-plus-circle text-lg/none me-3" /> Add Super
                Category
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <div
            className="shadow rounded-lg bg-white dark:bg-default-50"
            style={{ marginBottom: "80px" }}
          >
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Super Category
                      </th>
                      {/* <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Image
                      </th> */}

                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Status
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterData.map((supcat, index) => (
                      <tr key={index}>
                        <td
                          scope="col"
                          className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                        >
                          {index + 1}
                        </td>
                        <td
                          scope="col"
                          className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                        >
                          {supcat.name}
                        </td>
                        {/* <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <img src={supcat.desktopImage} alt="catimg" height='50' width='50' />
                          </td> */}

                        <td
                          scope="col"
                          className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                        >
                          <Switch
                            {...label}
                            defaultChecked={supcat.status === true}
                          />
                        </td>

                        <td
                          scope="col"
                          className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                        >
                          <Link
                            to={`/admin/updatesupersategory/${supcat.slugUrl}`}
                          >
                            <button
                              style={{
                                backgroundColor: "#1976d2",
                                color: "#fff",
                                padding: "10px 20px",
                                borderRadius: "5px",
                              }}
                            >
                              Edit
                            </button>
                          </Link>
                        </td>
                        <td
                          scope="col"
                          className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                        >
                          <button
                            style={{
                              backgroundColor: "#d32f2f",
                              color: "#fff",
                              padding: "10px 20px",
                              borderRadius: "5px",
                            }}
                            onClick={() => showModal(supcat)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Modal
            title="Basic Modal"
            open={isDeleteOpen}
            onCancel={handleCancel}
            footer={[
              <Button
                style={{
                  padding: "10px 15px",
                  color: "#fff",
                  backgroundColor: "#FF0000",
                }}
                onClick={() => setIsDeleteOpen(false)}
              >
                Cancel
              </Button>,
              <Button
                style={{
                  padding: "10px 15px",
                  color: "#fff",
                  backgroundColor: "#04681e",
                }}
                onClick={() => handleDelete()}
              >
                Confirm
              </Button>,
            ]}
          >
            <p>
              Do you want to delete Super Category With Name:{" "}
              <span style={{ color: "red" }}>{selectedProduct.name}</span>
            </p>
          </Modal>
        </div>
      </div>
    </Fragment>
  );
};

export default SuperCategoryList;
