import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
const initialState = {
  orderList: [],
  bookedOrder: [],
  processedOrder: [],
  dispatchedOrder: [],
  outOfDeliveryOrder: [],
  deliveredOrder: [],
  cancelledOrder: [],
  orderLoading: true,
  isOrderAvailable: false,
};
export const getOrders = createAsyncThunk("order/getOrders", async () => {
  try {
    const url = `${Baseurl}/api/v1/order/all`;
    const resp = await axios.get(url);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
});

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.isOrderAvailable = false;
        state.orderLoading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.orderList = action.payload.orders;
        }
        state.bookedOrder = state.orderList.filter(
          (indOrder) => indOrder.Status === 1
        );
        state.processedOrder = state.orderList.filter(
          (indOrder) => indOrder.Status === 2
        );
        state.dispatchedOrder = state.orderList.filter(
          (indOrder) => indOrder.Status === 3
        );
        state.outOfDeliveryOrder = state.orderList.filter(
          (indOrder) => indOrder.Status === 4
        );
        state.deliveredOrder = state.orderList.filter(
          (indOrder) => indOrder.Status === 5
        );
        state.cancelledOrder = state.orderList.filter(
          (indOrder) => indOrder.Status === 6
        );
        state.isOrderAvailable = true;
        state.orderLoading = false;
      })
      .addCase(getOrders.rejected, (state) => {
        state.isOrderAvailable = false;
        state.orderLoading = true;
      });
  },
});

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;

