import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import {
  categoryActions,
  categoryImages,
  categoryPost,
  categoryUpdate,
  validateSlugUrl,
} from "../../redux/Category/CategorySlice";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const EditCategory = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { desktopimage, imageLoading } = useSelector((store) => store.category);
  const [categoryId, setCategoryId] = useState("");
  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkSlugUrl, setCheckSlugUrl] = useState("");
  const [nameError, setNameError] = useState("");
  const [errorcolor, setErrorcolor] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [status, setStatus] = useState(false);
  const [priority, setpriority] = useState("");


  useEffect(() => {
    const getCategory = async () => {
      if (params.slugurl) {
        const url = `${Baseurl}/api/v1/category/getCategoryBySlugUrl/${params.slugurl}`;
        const resp = await axios.get(url);
        console.log(resp.data);
        if (resp.data.success) {
          setCategoryId(resp.data.category._id);
          setName(resp.data.category.name);
          setCheckSlugUrl(resp.data.category.slugUrl);
          setMetaTitle(resp.data.category.metaTitle);
          setMetaKeyword(resp.data.category.metaKeyword);
          setMetaDesc(resp.data.category.metaDesc);
          setStatus(resp.data.category.status);
          setpriority(resp.data.category.priority);
          const formData = {
            status: false,
            image: resp.data.category.desktopImage,
          };
          await dispatch(categoryActions.setImageNull(formData));
        }
      }
    };
    getCategory();
  }, [params.slugurl]);

  const handleSwitchChange = (event) => {
    const newChecked = event.target.checked;
    setStatus(newChecked);
  };

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckSlugUrl("");
    const nameSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(nameSlug);
    const response = await dispatch(validateSlugUrl(nameSlug));
    if (response.payload.success) {
      setNameError("Category alredy exist");
      setErrorcolor("red");
    } else if (nameSlug.length <= 2) {
      setErrorcolor("red");
      setNameError("minimum 3 letters");
      setCheckSlugUrl("");
    } else if (nameSlug.length >= 3) {
      setErrorcolor(" ");
      setNameError("");
      setCheckSlugUrl(nameSlug);
    }
  };
  const categoryImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCategory = async (e) => {
    const formData = {
      _id: categoryId,
      name: name,
      slugUrl: checkSlugUrl,
      mobileImage: desktopimage,
      desktopImage: desktopimage,
      metaTitle: metaTitle,
      metaKeyword: metaKeyword,
      metaDesc: metaDesc,
      status: status,
      priority: priority,
    };
    console.log(formData, "formData");
    const resp = await dispatch(categoryUpdate(formData));
    if (resp.payload.success) {
      alert("Category Updated");
      setName("");
      setMetaDesc("");
      setMetaKeyword("");
      setMetaTitle("");
      setStatus(false);
      setpriority("");
      const formData = {
        status: true,
        image: "",
      };
      await dispatch(categoryActions.setImageNull(formData));
      navigate("/admin/listcategory");
    }
  };
  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Edit Category Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className={
                          nameError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={name}
                        onChange={(e) => verifyslugurl(e)}
                      />
                    </div>
                  </div>
                  <div style={{ color: "red" }}>{nameError}</div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Title :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaTitle}
                        onChange={(e) => setMetaTitle(e.target.value)}
                        // className={classes.textfield}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Keyword :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaKeyword}
                        onChange={(e) => setMetaKeyword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Desc :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaDesc}
                        onChange={(e) => setMetaDesc(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-4 mt-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Priority :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={priority}
                        onChange={(e) => setpriority(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "600", color: "#000066" }}
                  >
                    Upload Category Image *
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="file"
                      className={
                        "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                      }
                      onChange={(e) => categoryImageChange(e)}
                    />
                  </div>
                  <div>
                    {imageLoading ? (
                      <></>
                    ) : (
                      <img
                        src={desktopimage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "600", color: "#000066" }}
                  >
                    Status *
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          id="statusSwitch"
                          checked={status}
                          onChange={handleSwitchChange}
                        />
                      }
                      label={status ? "Yes" : "No"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            {/* <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button> */}
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={() => handleCategory()}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
