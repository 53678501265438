import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import {
  brandPost,
  brandImages,
  validatebrandSlugUrl,
  brandActions,
} from "../../redux/Brand/BrandSlice";
import { Select } from "antd";
import { MenuItem } from "@mui/material";

const AddBrand = () => {
  const { desktopImage, imageLoading, mobileimage } = useSelector(
    (store) => store.brand
  );

  const { superCatTotal } = useSelector((store) => store.superCategory);
  const dispatch = useDispatch();

  const [superCategory, setSuperCategory] = useState("");
  const [superCategoryId, setSuperCategoryId] = useState("");
  const [brand, setBrand] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [show, setShow] = useState(Boolean(0));
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const handleSuperCatgory = (e) => {
    setSuperCategoryId(e);
    const getSuperCategoryName = superCatTotal.find(
      (indCat) => indCat._id === e
    );
    if (getSuperCategoryName) {
      setSuperCategory(getSuperCategoryName);
    }
  };

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setBrand(value);
    setCheckSlugUrl("");
    const brandSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(brandSlug);

    if (brandSlug !== "") {
      let responce = await dispatch(validatebrandSlugUrl(brandSlug));
      if (responce.payload.success) {
        setError("Brand alredy exist");
        setErrorcolor("red");
      } else if (brandSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (brandSlug.length >= 3) {
        setErrorcolor(" ");
        setError("");
        setCheckSlugUrl(brandSlug);
      }
    }
  };

  const brandImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(brandImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handlesubmit = async (e) => {
    if (checkslugUrl !== "") {
      const formData = {
        name: brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        superCategoryId: superCategoryId,
        superCategory: superCategory.name,
        superCategoryUrl: superCategory.slugUrl,
        desktopImage: desktopImage,
        mobileImage: mobileimage,
        show: show,
      };
      console.log(formData, "formData");
      const data = await dispatch(brandPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Brand Added");
          setBrand("");
          setSuperCategory("");
          setSlugUrl("");
          const formData = {
            status: true,
            image: "",
          };
          await dispatch(brandActions.setBrandImage(formData));
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  const handleReset = async () => {
    setBrand("");
    const formData = {
      status: true,
      image: "",
    };
    await dispatch(brandActions.setBrandImage(formData));
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Brand Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Super Category:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="super-category-label"
                          className="textfield"
                          label="Super Category"
                          id="demo-simple-select"
                          inputProps={{ style: { textAlign: "center" } }}
                          value={superCategoryId}
                          onChange={(e) => handleSuperCatgory(e)}
                        >
                          {superCatTotal &&
                            superCatTotal.map((supcat, index) => (
                              <MenuItem key={index} value={supcat._id}>
                                {supcat.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={brand}
                        required
                        onChange={(e) => verifyslugurl(e)}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="space-y-1"
                  style={{
                    paddingTop: "50px",
                    marginBottom: "50px",
                  }}
                >
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "600", color: "#000066" }}
                  >
                    Upload Brand Image *
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="file"
                      className={
                        "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                      }
                      onChange={(e) => brandImageChange(e)}
                    />
                  </div>
                  <div>
                    {imageLoading ? (
                      <></>
                    ) : (
                      <img
                        src={desktopImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              onClick={() => handleReset()}
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={() => handlesubmit()}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBrand;
