import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  coupontotal: localStorage.getItem("coupontotal")
    ? JSON.parse(localStorage.getItem("coupontotal"))
    : [],
  isLoading: true,
  couponLoading: true,
  delcouponLoading: true,
};

export const getCoupon = createAsyncThunk(
  "Coupon/getCoupon",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/coupon/all`;
      const resp = await axios(url);
      return resp.data.coupons;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const couponPost = createAsyncThunk(
  "coupon/couponPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/coupon/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("coupon Not create");
    }
  }
);

export const couponUpdate = createAsyncThunk(
  "coupon/couponUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/coupon/${formData.couponid}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("coupon Not create");
    }
  }
);

export const couponDelete = createAsyncThunk(
  "coupon/couponDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/coupon/${id}`;
      const resp = await axios.delete(url, id, config);
      
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("coupon Not create");
    }
  }
);

const CouponSlice = createSlice({
  name: "Coupons",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getCoupon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCoupon.fulfilled, (state, action) => {
        state.coupontotal = action.payload;
        state.isLoading = false;
        state.couponLoading = false;
        localStorage.setItem("coupontotal", JSON.stringify(state.coupontotal));
      })
      .addCase(getCoupon.rejected, (state) => {
        state.isLoading = true;
      })

      .addCase(couponPost.pending, (state) => {
        state.couponLoading = true;
      })
      .addCase(couponPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.coupontotal = [...state.coupontotal, action.payload.coupon];
          localStorage.setItem(
            "coupontotal",
            JSON.stringify(state.coupontotal)
          );
        }
        state.couponLoading = false;
      })
      .addCase(couponPost.rejected, (state) => {
        state.couponLoading = true;
      })

      .addCase(couponUpdate.pending, (state) => {
        state.couponLoading = true;
      })
      .addCase(couponUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.coupontotal = state.coupontotal.filter(
            (coupon) => coupon._id !== action.payload.coupon._id
          );
          state.coupontotal = [...state.coupontotal, action.payload.coupon];
          localStorage.setItem(
            "coupontotal",
            JSON.stringify(state.coupontotal)
          );
        }
        state.couponLoading = false;
      })
      .addCase(couponUpdate.rejected, (state) => {
        state.couponLoading = true;
      })

      .addCase(couponDelete.pending, (state) => {
        state.delcouponLoading = true;
      })
      .addCase(couponDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.coupontotal = state.coupontotal.filter(
            (coupon) => coupon._id !== action.payload.id
          );
          localStorage.setItem(
            "coupontotal",
            JSON.stringify(state.coupontotal)
          );
        }
        state.delcouponLoading = false;
      })
      .addCase(couponDelete.rejected, (state) => {
        state.delcouponLoading = true;
      })
  },
});
export default CouponSlice.reducer;
