import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  subcatbannertotal: localStorage.getItem("subcatbannertotal")
    ? JSON.parse(localStorage.getItem("subcatbannertotal"))
    : [],
  mobileimage: "",
  desktopimage: "",
  mobileimageLoading: true,
  desktopimageLoading: true,
  isLoading: true,
  subCatBannerLoading: true,
  delSubCatBannerLoading: true,
};

export const getSubCatBanner = createAsyncThunk(
  "SubCatBanner/getSubCatBanner",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcatbanner/all`;
      const resp = await axios(url);
      return resp.data.subcategorybanners;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const subCatBannerPost = createAsyncThunk(
  "subCatBanner/subCatBannerPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcatbanner/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcatbanner Not create");
    }
  }
);

export const validateSubCatBannerUrl = createAsyncThunk(
  "SubCatBannerUrl/validateSubCatBannerUrl",
  async (url, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/subcatbanner/url/${url}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const subCatBannerMobileImages = createAsyncThunk(
  "subCatBannerMobile/subCatBannerMobileImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcatbanner/slidermobileimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("slidermobileimages Not create");
    }
  }
);

export const subCatBannerDesktopImages = createAsyncThunk(
  "BannerDesktopImages/subCatBannerDesktopImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcatbanner/sliderdeskimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("sliderdeskimages Not create");
    }
  }
);

export const subCatBannerUpdate = createAsyncThunk(
  "subCatBanner/subCatBannerUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcatbanner/updatesubcatbanner/${formData.subCatBannerid}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcatbanner Not create");
    }
  }
);

export const subCatBannerDelete = createAsyncThunk(
  "Subcatbanner/subCatBannerDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcatbanner/deletesubcatbanner/${id}`;

      const resp = await axios.delete(url, id, config);
      console.log(resp.data);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcatbanner Not create");
    }
  }
);

const SubCatBannerSlice = createSlice({
  name: "Subcatbanner",
  initialState,
  reducers: {
    updateSubCatBannerMobileImage(state, action) {
      state.mobileimage = action.payload;
      state.mobileimageLoading = false;
    },
    updateSubCatBannerDeskImage(state, action) {
      state.desktopimage = action.payload;
      state.desktopimageLoading = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSubCatBanner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubCatBanner.fulfilled, (state, action) => {
        state.subcatbannertotal = action.payload;
        state.isLoading = false;
        state.subCatBannerLoading = false;
        localStorage.setItem(
          "subcatbannertotal",
          JSON.stringify(state.subcatbannertotal)
        );
      })
      .addCase(getSubCatBanner.rejected, (state) => {
        state.isLoading = true;
      })

      .addCase(subCatBannerPost.pending, (state) => {
        state.subCatBannerLoading = true;
      })
      .addCase(subCatBannerPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subcatbannertotal = [
            ...state.subcatbannertotal,
            action.payload.subcategorybanner,
          ];
          localStorage.setItem(
            "subcatbannertotal",
            JSON.stringify(state.subcatbannertotal)
          );
        }
        state.subCatBannerLoading = false;
      })
      .addCase(subCatBannerPost.rejected, (state) => {
        state.subCatBannerLoading = true;
      })
      .addCase(subCatBannerMobileImages.pending, (state) => {
        state.mobileimageLoading = true;
      })
      .addCase(subCatBannerMobileImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileImages;
        }
        state.mobileimageLoading = false;
      })
      .addCase(subCatBannerMobileImages.rejected, (state) => {
        state.mobileimageLoading = true;
      })

      .addCase(subCatBannerDesktopImages.pending, (state) => {
        state.desktopimageLoading = true;
      })
      .addCase(subCatBannerDesktopImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.desktopimage = action.payload.desktopImages;
        }
        state.desktopimageLoading = false;
      })
      .addCase(subCatBannerDesktopImages.rejected, (state) => {
        state.desktopimageLoading = true;
      })

      .addCase(subCatBannerUpdate.pending, (state) => {
        state.subCatBannerLoading = true;
      })
      .addCase(subCatBannerUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subcatbannertotal = state.subcatbannertotal.filter(
            (subcategorybanner) =>
              subcategorybanner._id !== action.payload.subcategorybanner._id
          );
          state.subcatbannertotal = [
            ...state.subcatbannertotal,
            action.payload.subcategorybanner,
          ];
          state.mobileimage = "";
          state.desktopimage = "";
          state.mobileimageLoading = true;
          state.desktopimageLoading = true;
          localStorage.setItem(
            "subcatbannertotal",
            JSON.stringify(state.subcatbannertotal)
          );
        }
        state.subCatBannerLoading = false;
      })
      .addCase(subCatBannerUpdate.rejected, (state) => {
        state.subCatBannerLoading = true;
      })

      .addCase(subCatBannerDelete.pending, (state) => {
        state.delSubCatBannerLoading = true;
      })
      .addCase(subCatBannerDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.subcatbannertotal = state.subcatbannertotal.filter(
            (subcatbanner) => subcatbanner._id !== action.payload.id
          );
          localStorage.setItem(
            "subcatbannertotal",
            JSON.stringify(state.subcatbannertotal)
          );
        }
        state.delSubCatBannerLoading = false;
      })
      .addCase(subCatBannerDelete.rejected, (state) => {
        state.delSubCatBannerLoading = true;
      });
  },
});

export const { updateSubCatBannerMobileImage, updateSubCatBannerDeskImage } =
  SubCatBannerSlice.actions;
export default SubCatBannerSlice.reducer;
